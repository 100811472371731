<template>
  <div>
    <section class="intro-md" data-background="assets/img/bg-7.png">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="block-text pt-lg-5">
              <p class="section-subheading">CREDIT SIMULATION</p>
              <h2 class="section-heading">
                Hitung simulasi kredit Mobil impian Anda
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="formulir-section section-lg pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <form method="post" action="javascript:void(0)" class="form-style">
              <div class="card">
                <!-- Data Kendaraan  -->
                <div class="card-header"><span>Data Kendaraan</span></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-10 m-auto">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label"
                                  >Kategori Mobil</label
                                >
                                <v-select
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="car_category"
                                  label="category_name"
                                  placeholder="Pilih Kategori Mobil"
                                  @input="setValIdkategoriMobil"
                                  :value="
                                    form_credit_simulation.car_category_name
                                  "
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Kategori Mobil tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.category_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Transmisi</label>
                                <v-select
                                  :disabled="
                                    form_credit_simulation.car_category_name ==
                                    ''
                                  "
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="car_transmission"
                                  label="label"
                                  placeholder="Pilih Transmisi Mobil"
                                  @input="setValIdTransmisi"
                                  :value="
                                    form_credit_simulation.car_transmission
                                  "
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Transmisi tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.label
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Model Mobil</label>
                                <v-select
                                  :disabled="
                                    form_credit_simulation.car_transmission ==
                                    ''
                                  "
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="car_model"
                                  label="car_mode_name"
                                  placeholder="Pilih Model Mobil"
                                  @input="setValIdCarModel"
                                  :value="form_credit_simulation.car_model_name"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Model Mobil tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.car_mode_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Tipe Mobil</label>
                                <v-select
                                  :disabled="
                                    form_credit_simulation.car_model_name == ''
                                  "
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="car_type"
                                  label="car_type_name"
                                  placeholder="Pilih Tipe Mobil"
                                  @input="setValIdCarType"
                                  :value="form_credit_simulation.car_type_name"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Tipe Mobil tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.car_type_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Credit Simulation  -->
                <div class="card-header"><span>Credit Simulation</span></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-10 m-auto">
                      <div class="row">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="control-label">Uang Muka</label>
                            <money
                              disabled
                              class="form-control text-right"
                              v-model="form_credit_simulation.uang_muka"
                            />
                            <b-form-input
                              v-if="uang_muka_min != 0 && uang_muka_max != 0"
                              variant="warning"
                              v-model="form_credit_simulation.uang_muka"
                              type="range"
                              :min="uang_muka_min"
                              :max="uang_muka_max"
                              :step="step_uang_muka"
                            ></b-form-input>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="form-group">
                            <label class="control-label">Pilih Tenor</label>
                            <v-select
                              class="vselect-custome"
                              style="width: 100%"
                              :options="tenor_option"
                              label="text"
                              placeholder="Pilih Tenor"
                              @input="setValIdTenor"
                              :value="form_credit_simulation.tenor"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Tenor tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.text
                              }}</template>
                            </v-select>
                          </div>
                        </div>

                        <div class="col-lg-3">
                          <div class="text-lg-right text-center">
                            <button
                              :disabled="form_credit_simulation.uang_muka == 0"
                              v-if="!isSave"
                              type="submit"
                              class="btn btn-padd btn-width text-center btn-yellow mt-3"
                              @click.prevent="hitung"
                            >
                              HITUNG <i class="fa fa-arrow-right ml-2"></i>
                            </button>
                            <button
                              v-if="isSave"
                              :disabled="isSave"
                              type="submit"
                              class="btn btn-padd btn-width text-center btn-yellow mt-3"
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                              ></span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <b-collapse
                        class="wrap-result-simulation hide-items"
                        v-model="visible"
                      >
                        <div
                          class="top-heading-product"
                          v-if="result_credit.length != 0"
                        >
                          <div class="heading-product">
                            <div class="container">
                              <div class="row">
                                <div
                                  class="col-lg-6 align-self-center mb-lg-0 mb-3"
                                >
                                  <h2 class="section-heading-xs mb-0">
                                    {{ result_credit.car_type_name }}
                                  </h2>
                                </div>

                                <div class="col-lg-6 px-lg-0 mb-lg-0 mb-3">
                                  <div class="price-display-2">
                                    <div class="box-price simulation">
                                      <h3 class="section-description">
                                        {{ result_credit.price_otr | currency }}
                                      </h3>
                                      <p class="section-description-sm mb-0">
                                        Harga OTR
                                      </p>
                                    </div>
                                    <div class="box-price simulation">
                                      <h3 class="section-description">
                                        {{ result_credit.angsuran | currency }}
                                      </h3>
                                      <p class="section-description-sm mb-0">
                                        per bulan |
                                        {{ result_credit.tenor }} Bulan Cicilan
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                  </div>
                </div>

                <!-- Instant Aproval  -->
                <div class="card-header"><span>Instan Approval</span></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-10 m-auto">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Email</label>
                            <input
                              v-model.trim="
                                $v.form_credit_simulation.email.$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_credit_simulation.email.$error,
                                'is-valid':
                                  !$v.form_credit_simulation.email.$error &&
                                  form_credit_simulation.email != '',
                              }"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Masukan email Anda"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_credit_simulation.email.required"
                            >
                              *Masukkan email!.
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_credit_simulation.email.email"
                            >
                              *Email tidak valid!.
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Upload KTP</label>
                            <div class="custome-input-file">
                              <div class="wrap-input">
                                <input
                                  accept="image/jpg, image/jpeg"
                                  type="file"
                                  name=""
                                  class="btn"
                                  @change="uploadKtp"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="invalid-feedback"
                            v-if="!$v.form_credit_simulation.ktp.required"
                          >
                            *Masukkan Scan KTP!.
                          </div>
                        </div>
                      </div>

                      <div class="text-lg-center text-center">
                        <button
                          :disabled="$v.form_credit_simulation.$invalid"
                          v-if="!isSave"
                          type="submit"
                          class="btn btn-padd btn-width text-center btn-yellow mt-4"
                          @click.prevent="submit"
                        >
                          KIRIM <i class="fa fa-arrow-right ml-2"></i>
                        </button>
                        <button
                          v-if="isSave"
                          :disabled="isSave"
                          type="submit"
                          class="btn btn-padd btn-width text-center btn-yellow mt-4"
                        >
                          <span class="spinner-border spinner-border-sm"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  name: "CreditSimulation",
  metaInfo() {
    return {
      title:
        "Credit Simulation - Dealer Toyota Semarang",
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  data() {
    return {
      tenor_option: [
        { text: "12 bulan", value: 12 },
        { text: "24 bulan", value: 24 },
        { text: "36 bulan", value: 36 },
        { text: "48 bulan", value: 48 },
        { text: "60 bulan", value: 60 },
      ],
      car_transmission: [
        { label: "AT", value: "AT" },
        { label: "MT", value: "MT" },
      ],
      visible: false,
      // uang muka min & max
      uang_muka_min: 0,
      uang_muka_max: 0,
      step_uang_muka: 0,
    };
  },

  created() {
    this.getCarCategory();
    window.scrollTo(0, 0);
  },
  mounted() {
    // attr data-background
    $("[data-background]").each(function () {
      $(this).css(
        "background-image",
        "url(" + $(this).attr("data-background") + ")"
      );
    });
  },
  watch: {
    form_credit_simulation: {
      handler(newValue) {
        if (newValue.car_category != "") {
          this.getCarModel({
            category_id: newValue.car_category,
          });
        }

        if (newValue.car_transmission != "" && newValue.car_model) {
          this.getCarType({
            car_model_id: newValue.car_model,
            car_transmission: newValue.car_transmission,
          });
        }
      },
      deep: true,
    },
  },
  validations: {
    form_credit_simulation: {
      car_category: { required },
      car_model: { required },
      car_transmission: { required },
      car_type: { required },
      uang_muka: { required },
      tenor: { required },
      email: { required, email },
      ktp: { required },
    },
  },
  computed: {
    ...mapState(["isSave"]),
    ...mapState(["errors"]),
    ...mapState("detail_car", {
      car_category: (state) => state.car_category,
      car_model: (state) => state.car_model,
      car_type: (state) => state.car_type,
      result_credit: (state) => state.result_credit,
      form_credit: (state) => state.form_credit,
    }),
    ...mapState("credit_simulation", {
      form_credit_simulation: (state) => state.form_credit_simulation,
    }),
  },
  methods: {
    ...mapMutations(["CLEAR_ERRORS"]),
    ...mapActions("detail_car", [
      "getCarCategory",
      "getCarModel",
      "getCarType",
      "getSimulationCar",
    ]),
    ...mapActions("credit_simulation", [
      "insertDataInstanApprove",
      "Clear_State",
    ]),

    uploadKtp(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      let limit = 1024 * 1024 * 2;
      if (file["size"] > limit) {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File terlalu besar pilih yang lain",
        });
        return false;
      }
      reader.onloadend = (file) => {
        this.form_credit_simulation.ktp = reader.result;
      };
      reader.readAsDataURL(file);
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    setValIdkategoriMobil(val) {
      this.form_credit_simulation.car_category = val.category_id;
      this.form_credit_simulation.car_category_name = val.category_name;

      this.visible = false;
      this.uang_muka_min = 0;
      this.uang_muka_max = 0;
      this.step_uang_muka = 0;
      this.form_credit_simulation.uang_muka = 0;
      this.form_credit_simulation.car_model = "";
      this.form_credit_simulation.car_model_name = "";
      this.form_credit_simulation.car_transmission = "";
      this.form_credit_simulation.car_type = "";
      this.form_credit_simulation.car_type_name = "";
    },
    setValIdCarModel(val) {
      this.visible = false;
      this.uang_muka_min = 0;
      this.uang_muka_max = 0;
      this.step_uang_muka = 0;
      this.form_credit_simulation.uang_muka = 0;
      this.form_credit_simulation.car_model = val.car_model_id;
      this.form_credit_simulation.car_model_name = val.car_mode_name;
      this.form_credit_simulation.car_type = "";
      this.form_credit_simulation.car_type_name = "";
    },
    setValIdTransmisi(val) {
      this.form_credit_simulation.car_transmission = val.value;
      this.form_credit_simulation.car_type = "";
      this.form_credit_simulation.car_type_name = "";
      this.uang_muka_min = 0;
      this.uang_muka_max = 0;
      this.step_uang_muka = 0;
      this.form_credit_simulation.uang_muka = 0;
    },
    setValIdCarType(val) {
      this.form_credit_simulation.car_type = val.car_type_id;
      this.form_credit_simulation.car_type_name = val.car_type_name;
      this.visible = false;
      this.form_credit_simulation.uang_muka = 0;
      const min = 0.3;
      const max = 0.8;
      const step = 0.1;
      this.uang_muka_min = val.price_otr * min;
      this.uang_muka_max = val.price_otr * max;
      this.step_uang_muka = val.price_otr * step;
      this.form_credit_simulation.uang_muka = this.uang_muka_min;
      this.form_credit_simulation.tenor = 12;
    },
    setValIdTenor(val) {
      this.form_credit_simulation.tenor = val.value;
    },

    //insert credit simulation
    submit() {
      this.$Progress.start();
      this.insertDataInstanApprove()
        .then((res) => {
          this.CLEAR_ERRORS();
          swal.fire({
            title: "Credit Simulation berhasil dikirim",
            icon: "success",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.$router.push({
            name: "Home",
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          swal.fire({
            text: this.errors,
            icon: "error",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.$Progress.fail();
        });
    },

    hitung() {
      this.$Progress.start();
      this.visible = false;
      this.getSimulationCar({
        car_model_id: this.form_credit_simulation.car_model,
        car_model_transmission: this.form_credit_simulation.car_transmission,
        car_model_type: this.form_credit_simulation.car_type,
        uang_muka: this.form_credit_simulation.uang_muka,
        tenor: this.form_credit_simulation.tenor,
      })
        .then((res) => {
          this.CLEAR_ERRORS();
          const min = 0.3;
          const max = 0.8;
          const step = 0.1;
          this.uang_muka_min = res.data.price_otr * min;
          this.uang_muka_max = res.data.price_otr * max;
          this.step_uang_muka = res.data.price_otr * step;
          this.visible = true;
          this.$Progress.finish();
        })
        .catch((error) => {
          toast.fire({
            icon: "error",
            title: this.errors,
          });
          this.visible = false;
          this.$Progress.fail();
        });
    },
  },
  destroyed() {
    this.Clear_State();
  },
};
</script>