<template>
  <div>
    <div>
      <!-- top slider  -->
      <div v-if="loading_car_id">
        <!-- slider  -->
        <section class="intro-detail-product">
          <!-- desktop -->
          <b-skeleton-img no-aspect height="350px"></b-skeleton-img>

          <!-- mobile -->
          <b-skeleton-img no-aspect height="350px"></b-skeleton-img>

          <div class="excellent container">
            <div class="flex-3-column">
              <div class="flex-col">
                <div class="items">
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </div>
              <div class="flex-col">
                <div class="items">
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </div>
              <div class="flex-col">
                <div class="items">
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="!loading_car_id">
        <!-- slider  -->
        <section class="intro-detail-product" v-if="car_sliders.length > 0">
          <!-- desktop -->
          <img
            v-for="(row_dekstop, index_desktop) in car_sliders"
            :key="'sp' + index_desktop"
            :data-src="row_dekstop.image"
            class="img-fluid lazyload img-product d-none d-md-block"
          />

          <!-- mobile -->
          <img
            v-for="(row_mobile, index_mobile) in car_sliders_mobile"
            :key="'sm' + index_mobile"
            :data-src="row_mobile.image"
            class="img-fluid lazyload img-product d-block d-md-none"
          />

          <div class="excellent container" v-if="car_featured.length > 0">
            <div class="flex-3-column">
              <div
                class="flex-col"
                v-for="(row_featured, index_featured) in car_featured"
                :key="'fr' + index_featured"
              >
                <div class="items">
                  <img
                    :data-src="row_featured.icon"
                    class="img-fluid lazyload icon"
                  />
                  <div class="">
                    <h4>{{ row_featured.subtitle }}</h4>
                    <p class="section-description">{{ row_featured.title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- top slider end  -->

      <!-- car  price  -->
      <div v-if="loading_car_default">
        <section class="top-heading-product">
          <!-- price car model  -->
          <div class="heading-product mt-4">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 align-self-center mb-lg-0 mb-3">
                  <h2 class="section-heading-xs mb-0">
                    <b-skeleton></b-skeleton>
                  </h2>
                </div>

                <div class="col-lg-4 px-lg-0 mb-lg-0 mb-3">
                  <div class="price-display-2">
                    <a class="box-price simulation">
                      <h3 class="section-description">
                        <b-skeleton animation="wave" width="100%"></b-skeleton>
                      </h3>
                      <p class="section-description-sm mb-0">
                        <b-skeleton animation="wave" width="100%"></b-skeleton>
                      </p>
                    </a>
                    <a class="box-price simulation">
                      <h3 class="section-description">
                        <b-skeleton animation="wave" width="100%"></b-skeleton>
                      </h3>
                      <p class="section-description-sm mb-0">
                        <b-skeleton animation="wave" width="100%"></b-skeleton>
                      </p>
                    </a>
                  </div>
                </div>

                <div
                  class="col-lg-4 align-self-center text-sm-center text-lg-right wrap-btn-mn"
                >
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
          <!-- price car  model end  -->

          <!-- credit simulation  -->

          <!-- credit simulation end  -->
          <div class="container wrap-btn-mb">
            <div class="row">
              <div
                class="col-lg-4 align-self-center text-sm-center text-lg-right"
              >
                <b-skeleton type="input"></b-skeleton>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="!loading_car_default">
        <section class="top-heading-product">
          <!-- price car model  -->
          <div class="heading-product mt-4">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 align-self-center mb-lg-0 mb-3">
                  <h2
                    v-if="form_credit_simulation.car_model_type_name != ''"
                    class="section-heading-xs mb-0"
                  >
                    {{ form_credit_simulation.car_model_type_name }}
                  </h2>
                  <h2
                    v-if="form_credit_simulation.car_model_type_name == ''"
                    class="section-heading-xs mb-0"
                  >
                    Pilih Tipe Mobil
                  </h2>
                </div>

                <div class="col-lg-4 px-lg-0 mb-lg-0 mb-3">
                  <div class="price-display-2">
                    <a
                      v-b-toggle.collapse-credit-simulation
                      class="box-price simulation"
                    >
                      <h3 class="section-description">
                        {{
                          form_credit_simulation.car_type_price_otr | currency
                        }}
                      </h3>
                      <p class="section-description-sm mb-0">Harga OTR</p>
                    </a>
                    <a
                      v-b-toggle.collapse-credit-simulation
                      class="box-price simulation"
                    >
                      <h3 class="section-description">
                        {{
                          form_credit_simulation.car_type_price_credit
                            | currency
                        }}
                      </h3>
                      <p class="section-description-sm mb-0">
                        per bulan | {{ form_credit_simulation.tenor }} Bulan
                        Cicilan
                      </p>
                    </a>
                  </div>
                </div>

                <div
                  class="col-lg-4 align-self-center text-sm-center text-lg-right wrap-btn-mn"
                >
                  <a
                    :class="{
                      disabled: $v.form_credit_simulation.$invalid,
                    }"
                    @click="toTestDriveFromCredit"
                    class="btn btn-lg btn-accent px-3 rounded-10"
                  >
                    TEST DRIVE
                    <span
                      class="icon trans-4 iconify"
                      data-inline="false"
                      data-icon="tabler:steering-wheel"
                    ></span>
                  </a>
                  <a
                    :class="{
                      disabled: $v.form_credit_simulation.$invalid,
                    }"
                    @click="toBuyToss"
                    class="btn btn-lg btn-yellow px-3 rounded-10 ml-2"
                  ><!--toBuyCarFromChoseType(row)-->
                    BUY A CAR
                    <span
                      class="icon trans-4 iconify"
                      data-inline="false"
                      data-icon="heroicons-outline:shopping-cart"
                    ></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- price car  model end  -->

          <!-- credit simulation  -->
          <b-collapse id="collapse-credit-simulation" class="wrap-simulation">
            <div class="container position-relative">
              <p class="section-description font-weight-600 opacity-7">
                Credit Simulation
              </p>

              <form method="post" class="form-style">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="control-label">Transmisi</label>
                          <v-select
                            class="vselect-custome"
                            style="width: 100%"
                            :options="Car_Transmision"
                            label="label"
                            placeholder="Pilih Transmisi Mobil"
                            @input="setValIdTransmisi"
                            :value="form_credit_simulation.car_transmision"
                            :clearable="false"
                          >
                            <template slot="no-options"
                              >Transmisi tidak ditemukan</template
                            >
                            <template slot="option" slot-scope="option">{{
                              option.label
                            }}</template>
                          </v-select>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="control-label">Tipe Mobil</label>
                          <v-select
                            class="vselect-custome"
                            style="width: 100%"
                            :options="car_type"
                            label="car_type_name"
                            placeholder="Pilih Tipe Mobil"
                            @input="setValIdCarType"
                            :value="form_credit_simulation.car_model_type_name"
                            :clearable="false"
                          >
                            <template slot="no-options"
                              >Tipe Mobil tidak ditemukan</template
                            >
                            <template slot="option" slot-scope="option">{{
                              option.car_type_name
                            }}</template>
                          </v-select>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="control-label">Uang Muka</label>
                          <money
                            disabled
                            class="form-control text-right"
                            v-model="form_credit_simulation.uang_muka"
                          />
                          <b-form-input
                            v-if="
                              form_credit_simulation.uang_min != 0 &&
                              form_credit_simulation.uang_max != 0
                            "
                            variant="warning"
                            v-model="form_credit_simulation.uang_muka"
                            type="range"
                            :min="form_credit_simulation.uang_min"
                            :max="form_credit_simulation.uang_max"
                            :step="form_credit_simulation.step_uang_muka"
                          ></b-form-input>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="control-label">Pilih Tenor</label>
                          <v-select
                            class="vselect-custome"
                            style="width: 100%"
                            :options="tenor_option"
                            label="text"
                            placeholder="Pilih Tenor"
                            @input="setValIdTenor"
                            :value="form_credit_simulation.tenor"
                            :clearable="false"
                          >
                            <template slot="no-options"
                              >Tenor tidak ditemukan</template
                            >
                            <template slot="option" slot-scope="option">{{
                              option.text
                            }}</template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </b-collapse>
          <!-- credit simulation end  -->
          <div class="container wrap-btn-mb">
            <div class="row">
              <div
                class="col-lg-4 align-self-center text-sm-center text-lg-right"
              >
                <a
                  :class="{
                    disabled: $v.form_credit_simulation.$invalid,
                  }"
                  @click="toTestDriveFromCredit"
                  class="btn btn-lg btn-accent px-3 rounded-10"
                >
                  TEST DRIVE
                  <span
                    class="icon trans-4 iconify"
                    data-inline="false"
                    data-icon="tabler:steering-wheel"
                  ></span>
                </a>
                <a
                  :class="{
                    disabled: $v.form_credit_simulation.$invalid,
                  }"
                  @click="toBuyToss"
                  class="btn btn-lg btn-yellow px-3 rounded-10 ml-2"
                >
                  BUY A CAR <!--toBuyCarFromCredit-->
                  <span
                    class="icon trans-4 iconify"
                    data-inline="false"
                    data-icon="heroicons-outline:shopping-cart"
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- car  price  end -->

      <!-- car youtube  -->
      <div v-if="loading_car_youtube">
        <section class="product-video-preview section-sm">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 m-auto">
                <div
                  class="card-video m-auto bg-image center d-flex align-items-center justify-content-center"
                >
                  <b-skeleton-img no-aspect height="450px"></b-skeleton-img>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="!loading_car_youtube">
        <section
          class="product-video-preview section-sm"
          v-if="video_youtube.cover != '' && video_youtube.url != ''"
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-10 m-auto">
                <div
                  class="card-video m-auto bg-image center d-flex align-items-center justify-content-center"
                >
                  <img
                    :data-src="video_youtube.cover"
                    class="img-fluid img-cover-video lazyload"
                  />
                  <a :href="video_youtube.url" class="popup-video">
                    <div class="play-button"><i class="fa fa-play"></i></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- car youtube end -->

      <!-- preview car  -->
      <div v-if="loading_car_id">
        <section class="preview-car section-sm">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 text-center">
                <div class="box-section-title">
                  <b-skeleton animation="wave" width="100%"></b-skeleton>
                </div>
              </div>

              <!-- color pallete  -->
              <div class="col-lg-12">
                <div class="preview-color-car">
                  <div class="nav-tabs-custome">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          role="tab"
                          aria-selected="true"
                        >
                          <b-skeleton type="avatar"></b-skeleton>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          role="tab"
                          aria-selected="true"
                        >
                          <b-skeleton type="avatar"></b-skeleton>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          role="tab"
                          aria-selected="true"
                        >
                          <b-skeleton type="avatar"></b-skeleton>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          role="tab"
                          aria-selected="true"
                        >
                          <b-skeleton type="avatar"></b-skeleton>
                        </a>
                      </li>
                    </ul>
                    <!-- image car  -->
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade"
                        role="tabpanel"
                        aria-labelledby="color1-tab"
                      >
                        <b-skeleton-img
                          no-aspect
                          height="350px"
                        ></b-skeleton-img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- eksterior & interior  -->
              <div class="col-lg-12">
                <div class="preview-features">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="card card-style card-features rounded-10">
                        <div class="card-body">
                          <b-skeleton-img
                            no-aspect
                            height="350px"
                          ></b-skeleton-img>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="card card-style card-features rounded-10">
                        <div class="card-body">
                          <div class="card-body">
                            <b-skeleton-img
                              no-aspect
                              height="350px"
                            ></b-skeleton-img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="!loading_car_id">
        <section class="preview-car section-sm" v-if="car_color.length != 0">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 text-center">
                <div class="box-section-title">
                  <h2 class="section-heading"><span>Make</span> it yours</h2>
                </div>
              </div>

              <!-- color pallete  -->
              <div class="col-lg-12">
                <div class="preview-color-car">
                  <div class="nav-tabs-custome">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li
                        class="nav-item"
                        v-for="(row_nav, index_nav) in car_color"
                        :key="'cv' + index_nav"
                      >
                        <a
                          :class="{
                            active: index_nav == 0,
                          }"
                          class="nav-link"
                          data-toggle="tab"
                          :href="'#' + row_nav.car_seo + row_nav.color"
                          role="tab"
                          aria-selected="true"
                        >
                          <div
                            class="color-product"
                            :style="{ background: row_nav.color_palette }"
                          ></div>
                          <p>{{ row_nav.color_name }}</p>
                        </a>
                      </li>
                    </ul>
                    <!-- image car  -->
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade"
                        v-for="(
                          row_image_color, index_image_color
                        ) in car_color"
                        :key="'im' + index_image_color"
                        :class="{
                          active: index_image_color == 0,
                          show: index_image_color == 0,
                        }"
                        :id="row_image_color.car_seo + row_image_color.color"
                        role="tabpanel"
                        aria-labelledby="color1-tab"
                      >
                        <img
                          :data-src="row_image_color.image"
                          class="img-fluid lazyload"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- eksterior & interior  -->
              <div class="col-lg-12">
                <div class="preview-features" v-if="car_exterior.length != 0">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="card card-style card-features rounded-10">
                        <img
                          :src="car_exterior.cover"
                          class="img-fluid img-cover"
                        />
                        <div class="card-body">
                          <h2 class="section-heading-xs text-white mb-1">
                            Eksterior
                          </h2>
                          <div
                            class="py-5 p-relative"
                            style="height: 65px"
                          ></div>
                        </div>
                        <div class="card-footer">
                          <CoolLightBox
                            :items="car_exterior.gallery"
                            :index="index"
                            @close="index = null"
                          >
                          </CoolLightBox>
                          <div class="images-wrapper">
                            <div
                              class="image"
                              v-for="(
                                row_eksterior, imageIndexExterior
                              ) in car_exterior.galery"
                              :key="'ex' + imageIndexExterior"
                              :style="{
                                backgroundImage: 'url(' + row_eksterior + ')',
                              }"
                            ></div>
                            <button
                              @click="index = 0"
                              type="button"
                              class="btn btn-padd btn-yellow rounded px-4"
                            >
                              <span>LEARN MORE</span>
                              <i class="fa fa-caret-right ml-3"> </i>
                            </button>
                          </div>
                          <a
                            v-if="car_exterior_360.length != 0"
                            @click="gotoEksteriorModal()"
                          >
                            <span class="text-white mr-2"
                              ><u>Take a Tour</u></span
                            >
                            <img
                              data-src="assets/img/icon/icon-360.png"
                              class="img-fluid icon-360 lazyloaded"
                              src="assets/img/icon/icon-360.png"
                            />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6" v-if="car_interior.length != 0">
                      <div class="card card-style card-features rounded-10">
                        <img
                          :src="car_interior.cover"
                          class="img-fluid img-cover"
                        />
                        <div class="card-body">
                          <h2 class="section-heading-xs text-white mb-1">
                            Interior
                          </h2>
                          <div
                            class="py-5 p-relative"
                            style="height: 65px"
                          ></div>
                        </div>
                        <div class="card-footer">
                          <CoolLightBox
                            :items="car_interior.gallery"
                            :index="index_interior"
                            @close="index_interior = null"
                          >
                          </CoolLightBox>
                          <div class="images-wrapper">
                            <div
                              class="image"
                              v-for="(
                                row_interior, imageIndexInterior
                              ) in car_interior.galery"
                              :key="'in' + imageIndexInterior"
                              :style="{
                                backgroundImage: 'url(' + row_interior + ')',
                              }"
                            ></div>
                            <button
                              @click="index_interior = 0"
                              type="button"
                              class="btn btn-padd btn-yellow rounded px-4"
                            >
                              <span>LEARN MORE</span>
                              <i class="fa fa-caret-right ml-3"> </i>
                            </button>
                          </div>

                          <a
                            v-if="pannelum_interior_360.length != 0"
                            data-target="#interior360-popup"
                            data-dismiss="modal"
                            data-toggle="modal"
                          >
                            <span class="text-white mr-2"
                              ><u>Take a Tour</u></span
                            >
                            <img
                              data-src="assets/img/icon/icon-360.png"
                              class="img-fluid icon-360 ls-is-cached lazyloaded"
                              src="assets/img/icon/icon-360.png"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- preview car  end-->

      <!-- slider car Type -->
      <div v-if="loading_car_type">
        <section class="section-sm">
          <div class="container">
            <div class="top-heading choose-type">
              <div class="box-section-title mb-0 text-center">
                <h2 class="section-heading mb-lg-0">
                  <b-skeleton animation="wave" width="100%"></b-skeleton>
                </h2>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="slider-container product-slider-container">
              <div class="owl-carousel owl-theme product-slider">
                <div class="owl-item">
                  <div class="card card-product">
                    <div class="card-header">
                      <b-skeleton-img no-aspect height="250px"></b-skeleton-img>
                    </div>

                    <div class="card-body">
                      <div class="wrap-padd">
                        <b-skeleton animation="wave" width="100%"></b-skeleton>

                        <b-skeleton animation="wave" width="100%"></b-skeleton>
                      </div>
                    </div>
                    <div class="card-footer">
                      <b-skeleton type="input"></b-skeleton>
                    </div>
                  </div>
                </div>
              </div>

              <div class="nav-dots">
                <div
                  class="slider-dots slider-dots-style product-slider-dots"
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="!loading_car_type">
        <section class="section-sm" v-if="owl_slider_product.length != 0">
          <div class="container">
            <div class="top-heading choose-type">
              <div class="box-section-title mb-0 text-center">
                <h2 class="section-heading mb-lg-0">
                  Choose <span>a Type</span>
                </h2>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="slider-container product-slider-container">
              <div
                class="owl-carousel owl-theme product-slider"
                v-if="loaded_slider_product"
              >
                <div
                  class="owl-item"
                  v-for="(row, index_at) in owl_slider_product"
                  :key="'cp' + index_at"
                >
                  <div class="card card-product">
                    <div class="card-header">
                      <div class="ribbon-category">
                        {{ row.car_category_name }} - {{ row.car_transmission }}
                      </div>
                      <img :data-src="row.image" class="img-fluid lazyload" />
                    </div>

                    <div class="card-body">
                      <div class="wrap-padd">
                        <h2 class="heading">{{ row.car_type_name }}</h2>

                        <div v-if="row.price_disc != 0" class="price-display-1">
                          <div class="box-price">
                            <del>{{ row.price_otr | currency }}</del>
                            <p class="price">{{ row.price_disc | currency }}</p>
                          </div>
                          <a
                            @click.prevent="
                              toDetailSpecs(
                                row.spesification.dimensi,
                                row.spesification.mesin,
                                row.spesification.spesifikasi
                              )
                            "
                            class="link"
                            >SPESIFIKASI
                            <i class="fa fa-chevron-right text-accent"></i
                          ></a>
                        </div>
                        <div v-else class="price-display-1">
                          <div class="box-price">
                            <p class="price mt-2">
                              {{ row.price_otr | currency }}
                            </p>
                          </div>
                          <a
                            @click.prevent="
                              toDetailSpecs(
                                row.spesification.dimensi,
                                row.spesification.mesin,
                                row.spesification.spesifikasi
                              )
                            "
                            class="link mb-2"
                            >SPESIFIKASI
                            <i class="fa fa-chevron-right text-accent"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        @click="toBuyToss()"
                        class="btn btn-block btn-lg btn-outline-dark font-weight-600 rounded"
                      >
                        BUY A CAR
                      </button> <!--toBuyCarFromChoseType(row)-->
                    </div>
                  </div>
                </div>
              </div>

              <div class="nav-dots">
                <div
                  class="slider-dots slider-dots-style product-slider-dots"
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- slider car Type end-->

      <!-- compare car  -->
      <section class="compare-car light">
        <div class="head-compare-car">
          <div class="container">
            <div
              class="wrap-head-compare d-md-flex align-items-center justify-content-between"
            >
              <div
                class="box-section-title mb-lg-0 mb-3 text-lg-left text-center"
              >
                <h2 class="section-heading-sm text-white mb-0">
                  Compare Your Dream Car
                </h2>
              </div>
              <div class="text-lg-right text-center">
                <button
                  :disabled="visible_compare"
                  type="button"
                  id="btn-compare"
                  class="btn btn-padd btn-yellow"
                  @click="openCompareCar()"
                >
                  COMPARE NOW
                </button>
              </div>
            </div>
          </div>
        </div>

        <b-collapse id="collapse-4" v-model="visible_compare" class="mt-2">
          <div class="wrap-box-compare section-sm hide-items" style="">
            <div class="container">
              <div class="box-compare">
                <a class="box-compare-dismiss" @click="closeCompareCar()"
                  ><span aria-hidden="true">×</span></a
                >
                <div class="flex-2-column">
                  <div class="flex-col">
                    <div class="box-container">
                      <div class="img-product-container mb-4">
                        <div
                          class="default-image"
                          v-if="compare_car.car_type_1 == ''"
                        >
                          <img
                            data-src="assets/img/icon/icon-default-compare.png"
                            class="img-fluid lazyloaded"
                            src="assets/img/icon/icon-default-compare.png"
                          />
                          <p class="section-description-sm">
                            Pilih Mobil untuk diperbandingkan
                          </p>
                        </div>
                        <div v-if="compare_car.car_type_1 != ''">
                          <div
                            v-if="result_car_1.image != ''"
                            class="img-product-container justify-content-center"
                          >
                            <img
                              :data-src="result_car_1.image"
                              class="img-fluid lazyload img-product w-75"
                            />
                          </div>

                          <div
                            v-if="result_car_1.image != ''"
                            class="py-2 w-100 bg-accent section-description-lg font-weight-bold text-center text-white rounded-0"
                          >
                            {{ result_car_1.price | currency }}
                          </div>
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label class="control-label opacity-7"
                              >Transmisi</label
                            >
                            <v-select
                              class="vselect-custome"
                              style="width: 100%"
                              :options="Car_Transmision"
                              label="label"
                              placeholder="Pilih Transmisi Mobil"
                              @input="setValIdTransmisi1"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Transmisi tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.label
                              }}</template>
                            </v-select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label class="control-label">Tipe Mobil</label>
                            <v-select
                              class="vselect-custome"
                              style="width: 100%"
                              :options="car_type_1"
                              label="car_type_name"
                              placeholder="Pilih Tipe Mobil"
                              @input="setValIdCarType1"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Tipe Mobil tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.car_type_name
                              }}</template>
                            </v-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex-col">
                    <div class="box-container">
                      <div class="img-product-container mb-4">
                        <div
                          class="default-image"
                          v-if="compare_car.car_type_2 == ''"
                        >
                          <img
                            data-src="assets/img/icon/icon-default-compare.png"
                            class="img-fluid lazyloaded"
                            src="assets/img/icon/icon-default-compare.png"
                          />
                          <p class="section-description-sm">
                            Pilih Mobil untuk diperbandingkan
                          </p>
                        </div>
                        <div v-if="compare_car.car_type_2 != ''">
                          <div
                            v-if="result_car_2.image != ''"
                            class="img-product-container justify-content-center"
                          >
                            <img
                              :data-src="result_car_2.image"
                              class="img-fluid lazyload img-product w-75"
                            />
                          </div>

                          <div
                            v-if="result_car_2.image != ''"
                            class="py-2 w-100 bg-accent section-description-lg font-weight-bold text-center text-white rounded-0"
                          >
                            {{ result_car_2.price | currency }}
                          </div>
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label class="control-label opacity-7"
                              >Transmisi</label
                            >
                            <v-select
                              class="vselect-custome"
                              style="width: 100%"
                              :options="Car_Transmision"
                              label="label"
                              placeholder="Pilih Transmisi Mobil"
                              @input="setValIdTransmisi2"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Transmisi tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.label
                              }}</template>
                            </v-select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="control-label">Tipe Mobil</label>
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="car_type_2"
                          label="car_type_name"
                          placeholder="Pilih Tipe Mobil"
                          @input="setValIdCarType2"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Tipe Mobil tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.car_type_name
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="box-bottom">
                  <button
                    :disabled="$v.compare_car.$invalid"
                    v-if="!isSave"
                    type="submit"
                    class="btn btn-padd text-center btn-yellow mt-1"
                    @click.prevent="submitCompareCar"
                  >
                    COMPARE NOW
                  </button>
                  <button
                    v-if="isSave"
                    :disabled="isSave"
                    type="submit"
                    class="btn btn-padd text-center btn-yellow mt-1"
                  >
                    <span class="spinner-border spinner-border-sm"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr />
        </b-collapse>

        <div class="head-compare-car mt-2" v-if="cta != ''">
          <div class="container">
            <div
              class="wrap-head-compare d-md-flex align-items-center justify-content-between"
            >
              <div
                class="box-section-title mb-lg-0 mb-3 text-lg-left text-center"
              >
                <h2 class="section-heading-sm text-white mb-0">
                  Do you have some questions about our service?
                </h2>
              </div>
              <div class="text-lg-right text-center">
                <a
                  :href="cta"
                  target="_blank"
                  class="btn btn-padd btn-whatsapp py-3 px-4"
                  ><i class="fab fa-whatsapp font-weight-200 mr-2"></i>WHATSAPP
                  MARKETING</a
                >
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- compare car end  -->
    </div>

    <!-- ------modal section---------- -->
    <!-- exterior 360  -->
    <div class="modal fade modal-style" id="eksterior360-popup">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style p-0">
            <div
              id="eksterior360"
              style="width: 100%; height: 400px"
              v-if="loaded_exterior_360"
            >
              <vue-three-sixty
                :amount="18"
                :imagePath="car_exterior_360.path"
                :fileName="car_exterior_360.filename"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- exterior 360 end  -->

    <!-- interior 360  -->
    <div
      class="modal fade modal-style"
      id="interior360-popup"
      aria-hidden="true"
      style="display: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style p-0">
            <div style="height: 400px" id="interior360"></div>
          </div>
          <!-- <v-pannellum
            :src="this.car_interior_360"
            style="height: 500px"
          ></v-pannellum> -->
        </div>
      </div>
    </div>
    <!-- interior 360 end -->

    <!-- spesifikasi  -->
    <div
      class="modal fade modal-style style-3"
      id="spesifikasi-modal"
      aria-hidden="true"
      style="display: none"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-lg"
        style="max-width: 1000px"
      >
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style">
            <div class="specification-product">
              <div id="accordion" class="accordion wrap-specification-collapse">
                <div class="card">
                  <a class="card-header" v-b-toggle.spesifikasi_dimensi>
                    <h6 class="section-description-sm mb-0 d-inline-block">
                      DIMENSI
                    </h6>
                  </a>
                  <b-collapse
                    visible
                    v-if="specs_car.dimensi.length != 0"
                    id="spesifikasi_dimensi"
                  >
                    <div class="card-body p-0">
                      <table class="table table-striped table-specification">
                        <tbody
                          v-for="(
                            row_dimensi, index_dimensi
                          ) in specs_car.dimensi"
                          :key="index_dimensi"
                        >
                          <tr>
                            <td>{{ row_dimensi.title }}</td>
                            <td>{{ row_dimensi.value }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                </div>

                <div class="card">
                  <a class="card-header" v-b-toggle.spesifikasi_mesin>
                    <h6 class="section-description-sm mb-0 d-inline-block">
                      SPESIFIKASI MESIN
                    </h6>
                  </a>
                  <b-collapse
                    v-if="specs_car.mesin.length != 0"
                    id="spesifikasi_mesin"
                  >
                    <div class="card-body p-0">
                      <table class="table table-striped table-specification">
                        <tbody
                          v-for="(row_mesin, index_mesin) in specs_car.mesin"
                          :key="index_mesin"
                        >
                          <tr>
                            <td>{{ row_mesin.title }}</td>
                            <td>{{ row_mesin.value }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                </div>

                <div class="card">
                  <a
                    v-if="specs_car.chasis.length != 0"
                    class="card-header"
                    v-b-toggle.spesifikasi_chasis
                  >
                    <h6 class="section-description-sm mb-0 d-inline-block">
                      CHASSIS
                    </h6>
                  </a>
                  <b-collapse
                    v-if="specs_car.chasis.length != 0"
                    id="spesifikasi_chasis"
                  >
                    <div class="card-body p-0">
                      <table class="table table-striped table-specification">
                        <tbody
                          v-for="(row_chasis, index_chasis) in specs_car.chasis"
                          :key="index_chasis"
                        >
                          <tr>
                            <td>{{ row_chasis.title }}</td>
                            <td>{{ row_chasis.value }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- spesifikasi end  -->

    <!-- modal result compare car -->
    <div class="modal fade modal-style style-3" id="result-compare-modal">
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
        style="max-width: 1000px"
      >
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style">
            <button
              type="button"
              class="close text-dark"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>

            <div class="specification-product pt-3">
              <table class="table table-striped table-specification">
                <tbody>
                  <tr class="header">
                    <td></td>
                    <td class="">
                      <div class="img-wrap" v-if="compare_car.car_type_1 != ''">
                        <img
                          :data-src="result_car.image_1"
                          class="img-fluid lazyload img-product w-75"
                        />
                      </div>
                      <h3 class="section-description-md">
                        {{ result_car.car_type_name_1 }}
                      </h3>
                      <p class="section-description-sm">
                        {{ result_car.price_1 | currency }}
                      </p>
                    </td>
                    <td>
                      <div class="img-wrap" v-if="compare_car.car_type_2 != ''">
                        <img
                          :data-src="result_car.image_2"
                          class="img-fluid lazyload img-product w-75"
                        />
                      </div>
                      <h3 class="section-description-md">
                        {{ result_car.car_type_name_2 }}
                      </h3>
                      <p class="section-description-sm">
                        {{ result_car.price_2 | currency }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div id="accordion" class="accordion wrap-specification-collapse">
                <div class="card">
                  <a class="card-header" v-b-toggle.compare_dimensi>
                    <h6 class="section-description-sm mb-0 d-inline-block">
                      DIMENSI
                    </h6>
                  </a>
                  <b-collapse visible id="compare_dimensi">
                    <div class="card-body p-0">
                      <table class="table table-striped table-specification">
                        <tbody
                          v-for="(row_dimensi, index_dimensi) in result_dimensi"
                          :key="index_dimensi"
                        >
                          <tr>
                            <td>{{ row_dimensi.title }}</td>
                            <td>{{ row_dimensi.value_1 }}</td>
                            <td>{{ row_dimensi.value_2 }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                </div>

                <div class="card">
                  <a class="card-header" v-b-toggle.compare_mesin>
                    <h6 class="section-description-sm mb-0 d-inline-block">
                      SPESIFIKASI MESIN
                    </h6>
                  </a>
                  <b-collapse id="compare_mesin">
                    <div class="card-body p-0">
                      <table class="table table-striped table-specification">
                        <tbody
                          v-for="(row_mesin, index_mesin) in result_mesin"
                          :key="index_mesin"
                        >
                          <tr>
                            <td>{{ row_mesin.title }}</td>
                            <td>{{ row_mesin.value_1 }}</td>
                            <td>{{ row_mesin.value_2 }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                </div>

                <div class="card">
                  <a class="card-header" v-b-toggle.compare_chasis>
                    <h6 class="section-description-sm mb-0 d-inline-block">
                      CHASSIS
                    </h6>
                  </a>
                  <b-collapse id="compare_chasis">
                    <div class="card-body p-0">
                      <table class="table table-striped table-specification">
                        <tbody
                          v-for="(row_chasis, index_chasis) in result_chasis"
                          :key="index_chasis"
                        >
                          <tr>
                            <td>{{ row_chasis.title }}</td>
                            <td>{{ row_chasis.value_1 }}</td>
                            <td>{{ row_chasis.value_2 }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal result compare car end  -->
  </div>
</template>

<script src="https://ufotostudio.com/nsmc/assets/vendors/360viewer/js-cloudimage-360-view.js"></script>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "DetailCar",
  metaInfo() {
    return {
      title: `${this.meta_title} - Toyota ${this.meta_title} Semarang`,
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  components: {
    CoolLightBox,
  },

  data() {
    return {
      exterior_360: {
        path: "",
        filename: "",
      },
      loaded_exterior_360: false,
      index: null,
      index_interior: null,
      filter_car_transmisi: "AT",
      tenor_option: [
        { text: "12 bulan", value: 12 },
        { text: "24 bulan", value: 24 },
        { text: "36 bulan", value: 36 },
        { text: "48 bulan", value: 48 },
        { text: "60 bulan", value: 60 },
      ],
      Car_Transmision: [
        { label: "AT", value: "AT" },
        { label: "MT", value: "MT" },
      ],
      // sliders product
      loaded_slider_product: false,
      owl_slider_product: [],
      // youtube
      loaded_youtube: false,
      video_youtube: [],
      // interior 360
      loaded_interior_360: false,
      pannelum_interior_360: [],

      // compare car
      visible_compare: false,

      // specs_car
      specs_car: {
        dimensi: [],
        chasis: [],
        mesin: [],
      },
    };
  },
  validations: {
    form_credit_simulation: {
      car_model_id: { required },
      car_model_name: { required },
      car_transmision: { required },
      car_model_type: { required },
      car_model_type_name: { required },
      tenor: { required },
      uang_muka: { required },
    },
    compare_car: {
      car_category_1: { required },
      car_model_1: { required },
      car_transmission_1: { required },
      car_type_1: { required },
      car_category_2: { required },
      car_model_2: { required },
      car_transmission_2: { required },
      car_type_2: { required },
    },
  },
  watch: {
    // credit simulation
    form_credit_simulation: {
      handler(newValue) {
        if (newValue.car_transmision != "" && newValue.car_model_id) {
          this.getCarType({
            car_model_id: newValue.car_model_id,
            car_transmission: newValue.car_transmision,
          });
        }
        if (
          newValue.car_transmision != "" &&
          newValue.car_model_id != "" &&
          newValue.car_model_type != "" &&
          newValue.uang_muka != 0 &&
          newValue.tenor != 0
        ) {
          this.getSimulationCar({
            car_model_id: newValue.car_model_id,
            car_model_transmission: newValue.car_transmision,
            car_model_type: newValue.car_model_type,
            uang_muka: newValue.uang_muka,
            tenor: newValue.tenor,
          });
        }
      },
      deep: true,
    },
    // compare car
    compare_car: {
      handler(newValue) {
        if (newValue.car_transmission_1 != "" && newValue.car_model_1 != "") {
          this.getCarType1({
            car_model_id: newValue.car_model_1,
            car_transmission: newValue.car_transmission_1,
          });
        }

        if (newValue.car_transmission_2 != "" && newValue.car_model_2 != "") {
          this.getCarType2({
            car_model_id: newValue.car_model_2,
            car_transmission: newValue.car_transmission_2,
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["isSave"]),
    ...mapState("detail_car", {
      car_category: (state) => state.car_category,
      car_model: (state) => state.car_model,
      car_type: (state) => state.car_type,

      loading_car_id: (state) => state.loading_car_id,
      meta_title: (state) => state.meta_title,
      car_model_name: (state) => state.car_model_name,
      car_model_seo: (state) => state.car_model_seo,
      car_model_id: (state) => state.car_model_id,
      car_sliders: (state) => state.car_sliders,
      car_sliders_mobile: (state) => state.car_sliders_mobile,
      loading_car_default: (state) => state.loading_car_default,
      car_default: (state) => state.car_default,
      car_featured: (state) => state.car_featured,
      loading_car_youtube: (state) => state.loading_car_youtube,
      car_youtube: (state) => state.car_youtube,
      car_color: (state) => state.car_color,
      loading_car_exterior: (state) => state.loading_car_exterior,
      car_exterior: (state) => state.car_exterior,
      car_exterior_360: (state) => state.car_exterior_360,
      loading_car_interior: (state) => state.loading_car_interior,
      car_interior: (state) => state.car_interior,
      car_interior_360: (state) => state.car_interior_360,
      loading_car_type: (state) => state.loading_car_type,
      car_detail_type: (state) => state.car_detail_type,
      cta: (state) => state.cta,

      form_credit_simulation: (state) => state.form_credit_simulation,
      loading_result_credit: (state) => state.loading_result_credit,
      result_credit: (state) => state.result_credit,

      ...mapState("test_drive", {
        form_test_drive: (state) => state.form_test_drive,
      }),
      ...mapState("booking_order", {
        form_booking_order_pick_car: (state) =>
          state.form_booking_order_pick_car,
        form_booking_order_payment_cash: (state) =>
          state.form_booking_order_payment_cash,
        form_booking_order_payment_credit: (state) =>
          state.form_booking_order_payment_credit,
        info_selected: (state) => state.info_selected,
      }),
    }),
    ...mapState("compare_car", {
      compare_car: (state) => state.compare_car,
      result_car_1: (state) => state.result_car_1,
      result_car_2: (state) => state.result_car_2,

      result_car: (state) => state.result_car,
      result_dimensi: (state) => state.result_dimensi,
      result_chasis: (state) => state.result_chasis,
      result_mesin: (state) => state.result_mesin,
      //car detail 1
      car_category_1: (state) => state.car_category_1,
      car_model_1: (state) => state.car_model_1,
      car_type_1: (state) => state.car_type_1,
      //car detail 2
      car_category_2: (state) => state.car_category_2,
      car_model_2: (state) => state.car_model_2,
      car_type_2: (state) => state.car_type_2,
    }),
  },
  methods: {
    ...mapMutations(["CLEAR_ERRORS"]),
    ...mapActions("detail_car", [
      "getDetailCarByID",
      "getCarModel",
      "getCarType",
      "getSimulationCar",
      "getDetailCarByIDType",
      "getDetailCarByIDEksteriorGalery",
      "getCarInterior360",
      "getCarExterior360",
      "getCarYoutube",
      "getCarDefault",
      "Clear_State",
    ]),

    ...mapActions("compare_car", [
      "getCarDefaultForCompareCar",
      "submitResult",
      "submitResultCompare",
      "getCarType1",
      "getCarType2",
      "Clear_State_On_Detail_Car",
      "Clear_State_Result_1",
      "Clear_State_Result_2",
    ]),
    ...mapActions("booking_order", ["getCarTypeOrder"]),
    toTestDriveFromCredit() {
      this.$Progress.start();
      this.form_test_drive.car_category = this.form_credit_simulation.car_category_id;
      this.form_test_drive.car_category_name = this.form_credit_simulation.car_category_name;
      this.form_test_drive.car_model = this.form_credit_simulation.car_model_id;
      this.form_test_drive.car_model_name = this.form_credit_simulation.car_model_name;
      this.form_test_drive.car_transmission = this.form_credit_simulation.car_transmision;
      this.form_test_drive.car_transmission_name = this.form_credit_simulation.car_transmision;
      this.form_test_drive.car_type = this.form_credit_simulation.car_model_type;
      this.form_test_drive.car_type_name = this.form_credit_simulation.car_model_type_name;
      this.$router.push({
        name: "Test_drive",
      });
      this.$Progress.finish();
    },
    toBuyCarFromCredit() {
      this.$Progress.start();
      this.form_booking_order_pick_car.car_category = this.form_credit_simulation.car_category_id;
      this.form_booking_order_pick_car.car_category_name = this.form_credit_simulation.car_category_name;

      this.info_selected.category_car = this.form_credit_simulation.car_category_name;
      this.form_booking_order_pick_car.car_model = this.form_credit_simulation.car_model_id;
      this.form_booking_order_pick_car.car_model_name = this.form_credit_simulation.car_model_name;
      this.info_selected.car_model_name = this.form_credit_simulation.car_model_name;

      this.form_booking_order_pick_car.car_transmission = this.form_credit_simulation.car_transmision;
      this.form_booking_order_pick_car.car_transmission_name = this.form_credit_simulation.car_transmision;
      this.info_selected.Transmisi = this.form_credit_simulation.car_transmision;
      this.form_booking_order_pick_car.car_type = this.form_credit_simulation.car_model_type;
      this.form_booking_order_pick_car.car_type_name = this.form_credit_simulation.car_model_type_name;
      this.info_selected.Tipe_mobil = this.form_credit_simulation.car_model_type_name;
      this.info_selected.car_pick_name = this.form_credit_simulation.car_model_type_name;

      this.getCarTypeOrder({
        car_model_id: this.form_booking_order_pick_car.car_model,
        car_transmission: this.form_booking_order_pick_car.car_transmission,
      });
      const min = 0.3;
      const max = 0.8;
      const step = 0.1;

      this.info_selected.Harga = this.form_credit_simulation.car_type_price_otr;
      this.form_booking_order_payment_cash.price_otr = this.form_credit_simulation.car_type_price_otr;
      this.form_booking_order_payment_credit.uang_min =
        this.form_booking_order_payment_cash.price_otr * min;
      this.form_booking_order_payment_credit.uang_max =
        this.form_booking_order_payment_cash.price_otr * max;
      this.form_booking_order_payment_credit.step_uang_muka =
        this.form_booking_order_payment_cash.price_otr * step;
      this.form_booking_order_payment_credit.uang_muka =
        this.form_booking_order_payment_cash.price_otr * min;

      this.$router.push({
        name: "Order",
      });
      this.$Progress.finish();
    },
    toBuyToss() {
      this.$Progress.start();
      this.$Progress.finish();
      window.open("https://toss.nasmoco.co.id", "_blank");
    },
    toBuyCarFromChoseType(data) { 
      this.$Progress.start();
      this.form_booking_order_pick_car.car_category = data.car_category_id;
      this.form_booking_order_pick_car.car_category_name =
        data.car_category_name;

      this.info_selected.category_car = data.car_category_name;
      this.form_booking_order_pick_car.car_model = data.car_model_id;
      this.form_booking_order_pick_car.car_model_name = data.car_model_name;
      this.info_selected.car_model_name = data.car_model_name;

      this.form_booking_order_pick_car.car_transmission = data.car_transmission;
      this.form_booking_order_pick_car.car_transmission_name =
        data.car_transmission;
      this.info_selected.Transmisi = data.car_transmission;
      this.form_booking_order_pick_car.car_type = data.car_type_id;
      this.form_booking_order_pick_car.car_type_name = data.car_type_name;
      this.info_selected.Tipe_mobil = data.car_type_name;
      this.info_selected.car_pick_name = this.form_credit_simulation.car_model_type_name;

      const min = 0.3;
      const max = 0.8;
      const step = 0.1;

      this.getCarTypeOrder({
        car_model_id: this.form_booking_order_pick_car.car_model,
        car_transmission: this.form_booking_order_pick_car.car_transmission,
      });
      if (data.price_disc != 0) {
        this.info_selected.Harga = data.price_disc;
        this.form_booking_order_payment_cash.price_otr = data.price_disc;
        this.form_booking_order_payment_credit.uang_min =
          this.form_booking_order_payment_cash.price_otr * min;
        this.form_booking_order_payment_credit.uang_max =
          this.form_booking_order_payment_cash.price_otr * max;
        this.form_booking_order_payment_credit.step_uang_muka =
          this.form_booking_order_payment_cash.price_otr * step;
        this.form_booking_order_payment_credit.uang_muka =
          this.form_booking_order_payment_cash.price_otr * min;
      } else {
        this.info_selected.Harga = data.price_otr;
        this.form_booking_order_payment_cash.price_otr = data.price_otr;
        this.form_booking_order_payment_credit.uang_min =
          this.form_booking_order_payment_cash.price_otr * min;
        this.form_booking_order_payment_credit.uang_max =
          this.form_booking_order_payment_cash.price_otr * max;
        this.form_booking_order_payment_credit.step_uang_muka =
          this.form_booking_order_payment_cash.price_otr * step;
        this.form_booking_order_payment_credit.uang_muka =
          this.form_booking_order_payment_cash.price_otr * min;
      }
      this.$router.push({
        name: "Order",
      });
      this.$Progress.finish();
    },
    indexGalery(index) {
      this.index = index;
    },
    setValIdTransmisi(val) {
      this.form_credit_simulation.car_transmision = val.value;

      this.form_credit_simulation.car_model_type = "";
      this.form_credit_simulation.car_model_type_name = "";

      this.form_credit_simulation.uang_min = "";
      this.form_credit_simulation.uang_max = "";
      this.form_credit_simulation.step_uang_muka = "";
      this.form_credit_simulation.uang_muka = "";
      this.form_credit_simulation.car_type_price_otr = 0;
      this.form_credit_simulation.car_type_price_credit = 0;
    },
    setValIdCarType(val) {
      this.form_credit_simulation.car_model_type = val.car_type_id;
      this.form_credit_simulation.car_model_type_name = val.car_type_name;

      const min = 0.3;
      const max = 0.8;
      const step = 0.1;

      this.form_credit_simulation.uang_min = val.price_otr * min;
      this.form_credit_simulation.uang_max = val.price_otr * max;
      this.form_credit_simulation.step_uang_muka = val.price_otr * step;
      this.form_credit_simulation.uang_muka = this.form_credit_simulation.uang_min;
    },
    setValIdTenor(val) {
      this.form_credit_simulation.tenor = val.value;
    },
    gotoEksteriorModal() {
      $("#eksterior360-popup").modal("show");
      this.getCarExterior360(this.$route.params.link)
        .then((res) => {
          this.exterior_360.path = res.data.path;
          this.exterior_360.filename = res.data.filename;
          if (this.exterior_360.path != "") {
            this.loaded_exterior_360 = true;
          }
        })
        .catch((err) => {
          if (err) console.log(err);
        });
    },
    // produk slider
    OwlCarouselRecomendedProduct: function () {
      if ($(".product-slider").length) {
        var productSlider = $(".product-slider");

        productSlider.owlCarousel({
          loop: false,
          autoplay: true,
          speed: 3800,
          smartSpeed: 2000,
          nav: false,
          autoWidth: false,
          // dots   	  : false,
          dots: true,
          dotsContainer: ".slider-dots",
          slidesToShow: 3,
          slidesToScroll: 1,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              margin: 20,
              stagePadding: 40,
            },
            576: {
              items: 2,
              margin: 20,
            },
            768: {
              items: 3,
              margin: 20,
            },
            992: {
              items: 3,
              margin: 20,
            },
            1200: {
              items: 4,
              margin: 20,
            },
          },
        });

        if ($(".product-slider-prev").length) {
          var prev = $(".product-slider-prev");
          prev.on("click", function () {
            productSlider.trigger("prev.owl.carousel");
          });
        }

        if ($(".product-slider-next").length) {
          var next = $(".product-slider-next");
          next.on("click", function () {
            productSlider.trigger("next.owl.carousel");
          });
        }
      }
    },

    panellumViewer: function () {
      pannellum.viewer("interior360", {
        type: "cubemap",
        cubeMap: this.pannelum_interior_360,
      });
    },

    PopupVideo: function () {
      $(".popup-video").magnificPopup({
        disableOn: 320,
        type: "iframe",
        removalDelay: 150,
        preloader: false,
        fixedContentPos: false,
      });
    },

    // compare car
    openCompareCar() {
      this.visible_compare = true;
    },
    closeCompareCar() {
      this.visible_compare = false;
    },

    // car 1
    setValIdTransmisi1(val) {
      this.compare_car.car_transmission_1 = val.value;
      this.compare_car.car_transmission_1_name = val.label;
    },
    setValIdCarType1(val) {
      this.compare_car.car_type_1 = val.car_type_id;
      this.compare_car.car_type_1_name = val.car_type_name;
      this.result_car_1.image = val.image;
      this.result_car_1.price = val.price;
    },

    //car 2
    setValIdTransmisi2(val) {
      this.compare_car.car_transmission_2 = val.value;
      this.compare_car.car_transmission_2_name = val.label;
    },
    setValIdCarType2(val) {
      this.compare_car.car_type_2 = val.car_type_id;
      this.compare_car.car_type_2_name = val.car_type_name;
      this.result_car_2.image = val.image;
      this.result_car_2.price = val.price;
    },

    submitCompareCar() {
      this.$Progress.start();
      this.submitResultCompare({
        car_model: this.compare_car.car_model_1,
        car_type_1: this.compare_car.car_type_1,
        car_type_2: this.compare_car.car_type_2,
      })
        .then((res) => {
          this.CLEAR_ERRORS();
          $("#result-compare-modal").modal("show");
          this.$Progress.finish();
        })
        .catch((error) => {
          toast.fire({
            icon: "error",
            title: this.errors,
          });
          this.$Progress.fail();
        });
    },

    // Specs Car
    toDetailSpecs(dimensi, mesin, spesifikasi) {
      // insert dimensi
      if (dimensi.length != 0) {
        this.specs_car.dimensi = dimensi;
      }
      // insert mesin
      if (mesin.length != 0) {
        this.specs_car.mesin = mesin;
      }
      // insert chasis
      if (spesifikasi.length != 0) {
        this.specs_car.chasis = spesifikasi;
      }
      $("#spesifikasi-modal").modal("show");
    },
  },
  mounted() {
    // car product
    var vm = this;
    this.getDetailCarByIDType(this.$route.params.link)
      .then((res) => {
        vm.owl_slider_product = res.data;
        if (vm.owl_slider_product.length > 0) {
          vm.loaded_slider_product = true;
          vm.$nextTick(
            function () {
              vm.OwlCarouselRecomendedProduct();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });

    // car youtube
    this.getCarYoutube(this.$route.params.link)
      .then((res) => {
        vm.video_youtube = res.data;
        if (vm.video_youtube.url != "") {
          vm.loaded_youtube = true;
          vm.$nextTick(
            function () {
              vm.PopupVideo();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });

    // interior 360
    this.getCarInterior360(this.$route.params.link)
      .then((res) => {
        vm.pannelum_interior_360 = res.data;
        if (vm.pannelum_interior_360.length > 0) {
          vm.loaded_interior_360 = true;
          vm.$nextTick(
            function () {
              vm.panellumViewer();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });

    $("[data-background]").each(function () {
      $(this).css(
        "background-image",
        "url(" + $(this).attr("data-background") + ")"
      );
    });
    $("[background-color]").each(function () {
      $(this).css("background-color", $(this).attr("background-color"));
    });
  },
  created() {
    this.getDetailCarByID(this.$route.params.link);
    this.getCarType({
      car_model_id: this.form_credit_simulation.car_model_id,
      car_transmission: this.form_credit_simulation.car_transmision,
    });
    this.getCarDefault(this.$route.params.link);
    this.getCarExterior360(this.$route.params.link);
    this.getCarDefaultForCompareCar(this.$route.params.link);
    window.scrollTo(0, 0);
  },
  destroyed() {
    this.Clear_State();
    this.Clear_State_On_Detail_Car();
  },
};
</script>