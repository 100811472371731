var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-6"},_vm._l((_vm.navigationmenu),function(row,index){return _c('div',{key:index,staticClass:"footer-column"},[(
                    row.category == 'INFO PERUSAHAAN' ||
                    row.category == 'PENJUALAN'
                  )?_c('div',[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(row.category))]),_vm._l((_vm.navigationmenu[index]
                      .detail_menu),function(row_c,index_c){return _c('ul',{key:index_c},[(row_c.name == 'Simulasi Kredit')?_c('li',[_c('router-link',{attrs:{"to":{
                          name: 'Credit_simulation',
                        },"href":"","data-dismiss":"modal","data-toggle":"modal","data-target":"#credit-modal"}},[_vm._v(_vm._s(row_c.name))])],1):(row_c.name == 'Jaringan Dealer')?_c('li',[_c('router-link',{attrs:{"to":{
                          name: 'Find_dealer',
                        },"href":"","data-dismiss":"modal","data-toggle":"modal","data-target":"#credit-modal"}},[_vm._v(_vm._s(row_c.name))])],1):(row_c.name == 'Book Test Drive')?_c('li',[_c('router-link',{attrs:{"to":{
                          name: 'Test_drive',
                        },"href":"","data-dismiss":"modal","data-toggle":"modal","data-target":"#credit-modal"}},[_vm._v(_vm._s(row_c.name))])],1):(row_c.name == 'Konsultasi Pembelian')?_c('li',[_c('router-link',{attrs:{"to":{
                          name: 'Contact_us',
                        },"href":"","data-dismiss":"modal","data-toggle":"modal","data-target":"#credit-modal"}},[_vm._v(_vm._s(row_c.name))])],1):(row_c.name == 'Pricelist')?_c('li',[_c('router-link',{attrs:{"to":{
                          name: 'Pricelist_page',
                        },"href":"","data-dismiss":"modal","data-toggle":"modal","data-target":"#credit-modal"}},[_vm._v(_vm._s(row_c.name))])],1):(row_c.name == 'Brochure')?_c('li',[_c('router-link',{attrs:{"to":{
                          name: 'Download_brochure',
                        },"href":"","data-dismiss":"modal","data-toggle":"modal","data-target":"#credit-modal"}},[_vm._v(_vm._s(row_c.name))])],1):_c('li',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.toSinglePage(row_c.link)}}},[_vm._v(_vm._s(row_c.name))])])])})],2):_vm._e()])}),0),_c('div',{staticClass:"col-lg-4 col-6"},_vm._l((_vm.navigationmenu),function(row,index){return _c('div',{key:index,staticClass:"footer-column"},[(
                    row.category == 'BERITA' || row.category == 'AFTERSALES'
                  )?_c('div',[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(row.category))]),_vm._l((_vm.navigationmenu[index]
                      .detail_menu),function(row_content,index_c){return _c('ul',{key:index_c},[(row_content.name == 'Book Service')?_c('li',[_c('router-link',{attrs:{"to":{
                          name: 'Booking_service',
                        },"href":""}},[_vm._v(_vm._s(row_content.name))])],1):(row_content.name == 'Toyota Genuine Part')?_c('li',[_c('router-link',{attrs:{"to":{
                          name: 'Sparepart',
                        },"href":""}},[_vm._v(_vm._s(row_content.name))])],1):(
                        row_content.name == 'Program Aftersales' ||
                        row_content.name == 'Info Service'
                      )?_c('li',[_c('router-link',{attrs:{"to":{
                          name: 'After-sale',
                        },"href":""}},[_vm._v(_vm._s(row_content.name))])],1):(row_content.name == 'News & Promo')?_c('li',[_c('router-link',{attrs:{"to":{
                          name: 'Promo',
                        },"href":""}},[_vm._v(_vm._s(row_content.name))])],1):(row_content.name == 'Info Garansi')?_c('li',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.toSinglePage('info-garansi')}}},[_vm._v(_vm._s(row_content.name))])]):(
                        row_content.name == 'Nasmoco Emergency Assistance'
                      )?_c('li',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.toSinglePage('nasmoco-emergency-asistance')}}},[_vm._v(_vm._s(row_content.name))])]):_vm._e()])})],2):_vm._e()])}),0),_vm._m(1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"logo-container"},[_c('img',{staticClass:"img-fluid lazyload logo",attrs:{"data-src":"/assets/img/logo-nasmoco-trans.png"}})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"logo-container"},[_c('a',{attrs:{"href":"https://toss.nasmoco.co.id","target":"_blank"}},[_c('img',{staticClass:"img-fluid lazyload",attrs:{"src":"/assets/img/logo-toss.png"}})])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-6"},[_c('div',{staticClass:"footer-column"},[_c('h4',{staticClass:"title"},[_vm._v("HUBUNGI KAMI")]),_c('p',[_vm._v("Layanan Pelanggan")]),_c('ul',{staticClass:"sosial-media"},[_c('li',[_c('a',{staticClass:"icon",attrs:{"href":"https://www.facebook.com/NasmocoWorld","target":"_blank"}},[_c('i',{staticClass:"fab fa-facebook"})])]),_c('li',[_c('a',{staticClass:"icon",attrs:{"href":"https://twitter.com/NASMOCOworld","target":"_blank"}},[_c('i',{staticClass:"fab fa-twitter"})])]),_c('li',[_c('a',{staticClass:"icon",attrs:{"href":"https://www.instagram.com/nasmoco_world/","target":"_blank"}},[_c('i',{staticClass:"fab fa-instagram"})])])])])]),_c('div',{staticClass:"col-lg-12 col-6"},[_c('div',{staticClass:"footer-column"},[_c('h4',{staticClass:"title"},[_vm._v("DOWNLOAD APPS")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=nasmoco.com.nasapp","target":"_blank"}},[_vm._v("Nasmoco Apps")])])])])])])])}]

export { render, staticRenderFns }